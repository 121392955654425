/* eslint-disable react/prop-types */
import React from "react"

import Heading from "../components/heading"
import image from "../images/contatti.jpg"
import Contatti from "../components/contatti"

const contatti = () => (
  <>
    <Heading 
      title="Contatti"
    />
      <div className="row">
        <div className="col-xl-10 offset-xl-1">
          <Contatti
            title="Studio Legale Pacchioni"
            image={image}
          />
        </div>
      </div>
  </>

)

export default contatti