import React from "react"

import contattiStyles from "./contatti.module.scss"

const Contatti = (props) => (
  <>
  <section className={contattiStyles.card}>
    <div className={contattiStyles.cardinfo}>
      <h3>{props.title}</h3>
      <hr/>
      <ul className={contattiStyles.cardlist}>
        <li className={contattiStyles.carditem}>
          <span className={contattiStyles.left}>indirizzo</span>
          <span className={contattiStyles.right}>Via Ganaceto, 123, 41121 Modena <br/> <span> <a href="https://www.google.com/maps/place/Via+Ganaceto,+123,+41121+Modena+MO/@44.6519446,10.9248627,17z/data=!3m1!4b1!4m5!3m4!1s0x477fef10c9574b23:0xec14bfc8cb709cb6!8m2!3d44.6519446!4d10.9270567"> Vedi su Google Maps → </a></span></span>
        </li>
        <li className={contattiStyles.carditem}>
          <span className={contattiStyles.left}>Tel</span>
          <span className={contattiStyles.right}><a href="tel:+39059243001">+39 059 243001</a> </span>
        </li>
        <li className={contattiStyles.carditem}>
          <span className={contattiStyles.left}>Fax</span>
          <span className={contattiStyles.right}><a href="fax:+390594398789">+39 059 4398789</a> </span>
        </li>
        <li className={contattiStyles.carditem}>
          <span className={contattiStyles.left}>Mail</span>
          <span className={contattiStyles.right}><a href="mailto:info@studiolegalepacchioni.it">info@studiolegalepacchioni.it</a> </span>
        </li>
      </ul>
    </div>
  </section>

  <figure className={contattiStyles.image}>
  <img src={props.image} alt=""/>
  </figure>
  </>
)

export default Contatti